import * as React from 'react';
import Img from 'gatsby-image';
import { ImageFluid } from 'components/Types';
import styles from './ava.module.css';

interface Props {
  img: ImageFluid;
  caption: React.ReactNode | string;
  link?: {
    to: string;
    title: string;
  };
}

function Ava({ img, caption, link }: Props) {
  return (
    <figure className={styles.container}>
      <Img fluid={img} className={styles.ava} alt="" />
      <figcaption>{caption}</figcaption>
      {link ? (
        <a href={link.to} className={styles.link} rel="noopener noreferrer">
          {link.title}
        </a>
      ) : null}
    </figure>
  );
}

export default Ava;
