import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
// styles
import clsx from 'clsx';
import styles from './evp.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Layout from '../components/item/layout';
import Resume from '../components/resume';
import Ava from '../components/ava';
import Team from '../components/team';
import VideoCase from '../components/video';
// other
import { meta } from '../../../metaData';

function EveryPigCase() {
  const data = useStaticQuery(graphql`
    query {
      chrisBomgaars: file(relativePath: { eq: "chris-bomgaars.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 215) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      nickBartlett: file(relativePath: { eq: "nick-bartlett.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 215) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "everypig-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1165) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "everypig-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "everypig-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "everypig-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "everypig-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      everypig: file(relativePath: { eq: "career/everypig.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      layoutClassName={styles.container}
      score={['Agritech', 'USA', 'November, 2016', 'Ongoing']}
      navBtn="#FB5A2C"
      headerTitle="Developing online logistics platform for swine production industry"
      subTitle="Acquire a sneak peek at how Brocoders strengthen an already-existing solution with a new powerful logistics core."
      className={styles.header}
      metaData={meta.everyPig}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          EveryPig is a leading high-end platform for managing the entire pork life-cycle, starting
          from production to delivery to sales. Powered by Artificial intelligence and Machine
          Learning features, EveryPig allows for planning the supply chain, gathering insightful and
          transparent data and collaborating with your team members as well as other stakeholders
          all along the road.
        </p>
        <p className={itemStyles.paragraph}>
          Chris Bomgaars currently serves as a Vice President at his family’s agricultural company,
          RC Family Farms, where he leads strategy, procurement and business development. Nick
          Bartlett is the co-founder of EveryPig, who is currently working as a designer and a
          customer service representative.
        </p>
        <p className={itemStyles.paragraph}>
          Driven by their disruptive ideas to revolutionize the pork industry, Chris Bomgaars and
          Nick Bartlett decided to create the EveryPig software application ─ a first of its kind
          pig health/welfare platform.
        </p>
        <div className={styles.avaGroup}>
          <Ava
            img={data.chrisBomgaars.childImageSharp.fluid}
            caption={
              <React.Fragment>
                <b>Chris Bomgaars</b> <br />
                CEO, EveryPig, Inc.
              </React.Fragment>
            }
            link={{ to: 'https://www.everypig.com/', title: 'everypig.com' }}
          />
          <Ava
            img={data.nickBartlett.childImageSharp.fluid}
            caption={
              <React.Fragment>
                <b>Nick Bartlett</b> <br />
                Co-founder, EveryPig, Inc.
              </React.Fragment>
            }
            link={{ to: 'https://www.everypig.com/', title: 'everypig.com' }}
          />
        </div>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          That said, our client Chris, the founder of EveryPig, had a great passion for innovations,
          so he was seeking to gain a competitive edge and enrich the user experience by
          transforming a pork production legacy system that was difficult to scale, maintain and
          integrate with third-party software.
        </p>
        <p className={itemStyles.paragraph}>
          To enhance the company’s digital presence, Chris decided to reach out to Brocoders to
          develop a multi-functioning solution that would allow for tracking the overall outcomes,
          acquiring AI-fueled data and providing remote veterinary services. We split the
          development process into two phases and successfully built a working product while meeting
          all the customer’s requirements.
        </p>
        <p className={itemStyles.paragraph}>
          Nevertheless, the project still lacked the opportunity to provide a decent pork production
          logistics. It’s worth noting that most large-scale producers utilized outdated and
          ineffective methodologies for tracking pigs during their transportation from farms to
          other farms, slaughterhouses or sales outlets, to name just a few.
        </p>
        <p className={itemStyles.paragraph}>
          To overcome these issues and connect all stakeholders in one single working chain, Chris
          ended up with the idea to create a full-cycle logistics platform that can automate all
          processes and provide a transparent data tracking from a breeding sow to a packaging plant
          stage.
        </p>
        <Resume services={['mvp', 'product']} technology={['react', 'rails']} />
        <h2 className={itemStyles.title}>Key challenges</h2>

        <VideoCase image={data.everypig.childImageSharp.fluid} source="everypig_2" />

        <p className={itemStyles.paragraph}>
          To support the client on his path, we launched the third phase to develop a new platform
          that would include the old data of the existing system, new innovative features as well as
          a new logistics core.
        </p>
        <p className={itemStyles.paragraph}>
          To enable a smooth and streamlined development process, as well as reduce the development
          time, we assigned the team of our in-house seasoned experts that had worked on previous
          EveryPig app versions:
        </p>
        <Team
          color="#FD5C2E"
          list={[
            { count: 3, title: 'Backend\nengineers' },
            { count: 3, title: 'Frontend\nengineers' },
            { count: 1, title: 'PM/BA' },
            { count: 1, title: 'QA\nengineer' },
          ]}
        />
        <p className={itemStyles.paragraph}>
          Our primary goal was to create an all-in-one logistics solution that combines both the
          logistics and the service (production) teams so that all interested parties can
          collaborate on the single industry platform. Besides, we wanted to make the entire process
          fully transparent to receive real-time feedback from active users.
        </p>
        <p className={itemStyles.paragraph}>
          The biggest challenge for us was to implement a new logistics part while ensuring the
          environment of the existing working system remains stable and functioning. Thanks to our
          deep understanding of the project, we managed to avoid most of the pitfalls, yet there
          were some issues we had to face anyway:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            To implement a logistics module, we had to entirely redesign the project and readjust
            the working solution.
          </li>
          <li>
            That said, there was a risk of damaging the existing structure while integrating new
            features, so it was vital to ensure every step was done correctly. The EveryPig core
            platform had active and sensitive users ― caregivers, so we had to work in a hassle-free
            mode to avoid critical changes in some of the vital functionalities, such as daily
            checkups, offline mode, AI-related sections, etc.
          </li>
        </ul>
        <p className={itemStyles.paragraph}>
          To maximize the final product’s KPI and make the working process for end-users as smooth
          and comfy as possible, we also empowered a new logistics platform with additional
          features:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Online GPS-enabled maps and routes</li>
          <li>Web sockets</li>
          <li>Push up notifications</li>
          <li>Personal admin settings panels for trucking admins (shipping companies owners)</li>
        </ul>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Delivered solution</h2>
        <p className={itemStyles.paragraph}>
          In light of a wide range of tasks before us, we’ve managed to build a fully-functioning
          MVP-based logistics platform, based on a large set of new functionalities and connections
          that provide full compliance with the active project and allow for a smooth app running.
        </p>
        <p className={itemStyles.paragraph}>
          We transformed a telemedicine solution into a full-cycle logistics platform to ensure the
          company is backed by powerful feature-rich technology. We also introduced three new user
          types to cover the entire supply chain. So there are five key end-users at the moment:
        </p>
        <img
          src={require('./key-end-users.svg')}
          alt="Key End-users schema"
          className={styles.schema}
        />
        <p className={styles.paragraph}>
          The logistics teams can now process and analyze in-depth info on pigs and farms throughout
          the entire supply chain, hence significantly boosting the overall workflow and maximizing
          their transportation planning efficiency. There are three major transportation types
          designed for various delivery scenarios:
        </p>
        <img src={require('./schema-2.svg')} alt="schema" className={styles.schema} />
      </div>
      <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <p className={itemStyles.paragraph}>
          Split into a few sectores, such an approach allows for better data mapping and analyzing,
          while augmenting the security algorithms.
        </p>
        <p className={itemStyles.paragraph}>
          Thanks to in-built online contracts, every stakeholder can now easily trace the pigs’
          movements all the way from farms to nurseries, slaughterhouses or packaging plants as
          well.
        </p>
        <p className={itemStyles.paragraph}>
          The online contract is a document that is prepared and created by the EveryPig’s logistics
          teams. To ensure exhaustive and transparent info is delivered to every interested party,
          the logistics teams fill in the most crucial data each time the transportation is
          scheduled:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Contract owner/creator, number and date</li>
          <li>Destination point</li>
          <li>The number of heads</li>
          <li>Pigs weight range</li>
          <li>Delivery frequency, status and date</li>
          <li>Specialty contract requirements (i.e. specific customers’ requests)</li>
        </ul>
        <p className={itemStyles.paragraph}>
          The logistics teams assign particular contracts to particular stakeholders, so they can
          access the attached online document whenever and wherever they are and come up with highly
          accurate data. Such a digital-driven approach helps the Agritech sector to minimize
          inefficient and time-wasting paperwork.
        </p>
        <p className={itemStyles.paragraph}>
          Besides, we meticulously reinvented the project’s design using the latest technologies,
          thus enabling fascinating usability and enhanced user experience. EveryPig Logistics is
          basically a completely new solution, empowered with the following remarkable features:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            An all-in-one platform that combines both production and logistics to drastically
            streamline and automate all processes, thus solving the industry’s crucial pain points.
            EveryPig Logistics ensures outstanding traceability throughout the entire supply chain
            and provides all parties with transparent real-time data.
          </li>
          <li>
            Trucking admins and truckers can easily monitor invaluable info and effectively manage
            their workflow right from a smartphone, a tablet or a computer. It works for other
            stakeholders the same way, of course.
          </li>
          <li>
            A highly competitive product with an entirely covered ecosystem that provides means to
            go head to head with other existing logistics solutions that manufacturers and packaging
            plants tend to use over the last years. In fact, it’s a top rival ready to replace those
            legacy systems.
          </li>
        </ul>
      </div>
      <Img fluid={data.screen3.childImageSharp.fluid} className={styles.screen1} alt="" />
      <Img fluid={data.screen4.childImageSharp.fluid} className={styles.screen1} alt="" />
      <Img fluid={data.screen5.childImageSharp.fluid} className={styles.screen1} alt="" />
      <div className={clsx(itemStyles.inner, itemStyles.lastItem)}>
        <h2 className={itemStyles.title}>The results</h2>
        <p className={itemStyles.paragraph}>
          Currently, the project is at the final development stage and is being prepared for the
          integration with the EveryPig’s existing infrastructure. Once the Logistics project is
          successfully launched, it can then replace legacy Microsoft Access software that EveryPig
          is using at the moment.
        </p>
      </div>
    </Layout>
  );
}

export default EveryPigCase;
